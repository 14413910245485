<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Representantes</titulo-pagina>
        </v-col>
        <v-col cols="12">
          <v-card class="rounded-lg">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn color="white" class="float-left" small @click="getDados">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn color="white" class="mx-3" small @click="modalFiltro = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>

                  <v-chip
                      v-for="(value, key) in filtersChip" :key="key"
                      v-if="(value != null && value.length > 0)"
                      class="mx-3"
                      close
                      color="orange"
                      label
                      outlined
                      @click:close="filtros[key] = null"
                  > {{ key | primeiraLetraMaiuscula }}: {{ value | formataData }}
                  </v-chip>
                  <v-btn class="ml-3 float-end" color="orange" to="/representantes/cadastrar" dark
                         small>
                    <v-icon>mdi-plus</v-icon>
                    Novo Representante
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-data-table
                      :headers="headers"
                      :items="dadosTabela"
                      locale="pt"
                  >
                    <template v-slot:item.criado_em="{ item }">
                      {{ item.criado_em | formataData }}
                    </template>
                    <template v-slot:item.cidade="{ item }">
                      {{ item.cidade }}/{{ item.uf }}
                    </template>

                    <template v-slot:item.valor_comissao_fixa="{ item }">
                      {{ item.valor_comissao_fixa | dinheiro }}
                    </template>
                    <template v-slot:item.valor_comissao_porcento="{ item }">
                      {{ item.valor_comissao_porcento }}%
                    </template>

                    <template v-slot:item.status_id="{ item }">
                      <v-btn text class="rounded-xl" readonly :color="item.status_id == 1 ? 'green' : 'red'">
                        {{ item.status_id == 1 ? 'Ativo' : 'Inativo' }}
                      </v-btn>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon @click="abrirInformacoes(item)">
                        <v-icon color="blue lighten-1" medium>mdi-information-outline</v-icon>
                      </v-btn>
                      <v-btn icon @click="irParaEdicao(item.id)">
                        <v-icon color="blue darken-4" medium>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="abrirUsuario(item)">
                        <v-icon :color="!item.usuario_id ? 'black darken-3': 'blue'" medium>mdi-shield-account</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <dialog-filtro
          ref="DialogFiltro"
          :aberto="modalFiltro"
          @fechar="modalFiltro = false"
          @filtrar="filtrar"
      />

      <dialog-detalhes
          :status="dialogDetalhes.status"
          :representante="dialogDetalhes.representante"
          @fechar="dialogDetalhes.status = false"
      />

    </v-container>
  </menu-principal>
</template>

<script>

import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import DialogFiltro from "@/components/representantes/DialogFitro";
import representantes from "@/services/representantes";
import DialogDetalhes from "@/components/representantes/DialogDetalhes";
import mixinGeral from "../../mixin/geral";

export default {
  name: "ReanaliseDeCadastro",
  mixins: [mixinGeral],
  components: {
    DialogDetalhes,
    MenuPrincipal,
    TituloPagina,
    DialogFiltro,
  },
  data() {
    return {
      dados: [],
      filtros: {
        nome_razao: null,
        cpf_cnpj: null,
        criado_em: null,
        status_id: null
      },
      modalFiltro: false,
      dialogDetalhes: {
        status: false,
        representante: {}
      },
      dialogUsuario: {
        status: false,
        representante: {}
      }
    }
  },
  async mounted() {
    this.getDados();
    this.filtros.status_id = 1;
  },
  computed: {
    headers() {
      return [
        {text: "CPF/CNPJ", value: "cpf_cnpj"},
        {text: "Nome/Razao", value: "nome_razao"},
        {text: "Cidade", value: "cidade"},
        {text: "Valor Fixo", value: "valor_comissao_fixa", align: "center"},
        {text: "Pcto por Venda", value: "valor_comissao_porcento", align: "center"},
        {text: "Status", value: "status_id", align: "center"},
        {text: "Data Cadastro", value: "criado_em", align: "center"},
        {text: "", value: "actions", align: "center"},
      ];
    },
    dadosTabela() {
      const dados = this.registrosFiltrados;
      if (dados.length === 0) {
        return []
      }
      return dados;
    },
    filtersChip() {
      const retorno = {};
      if (this.filtros.nome_razao) retorno.nome_razao = `${this.filtros.nome_razao}`;
      if (this.filtros.cpf_cnpj) retorno.cpf_cnpj = `${this.filtros.cpf_cnpj}`;
      if (this.filtros.criado_em) retorno.criado_em = `${this.filtros.criado_em}`;
      switch (this.filtros.status_id) {
        case 1:
          retorno.status_id = `Ativo`;
          break;
        case 2:
          retorno.status_id = `Inativo`;
          break;
      }

      return retorno;
    },
    registrosFiltrados() {
      if (!this.dados) return [];
      let filtrados = [...this.dados];

      const filtrandoNome = !!this.filtros.nome_razao;
      const filtrandoCpf = !!this.filtros.cpf_cnpj;
      const filtrandocriado_em = !!this.filtros.criado_em;
      const filtrandoSatusId = !!this.filtros.status_id;

      if (filtrandoNome) filtrados = filtrados.filter((f) => {
        return f.nome_razao.toUpperCase().indexOf(this.filtros.nome_razao.toUpperCase()) !== -1;
      });
      if (filtrandoCpf) filtrados = filtrados.filter((f) => {
        return f.cpf_cnpj.toUpperCase().indexOf(this.filtros.cpf_cnpj.toUpperCase()) !== -1;
      });
      if (filtrandocriado_em) filtrados = filtrados.filter((f) => {
        return f.criado_em.slice(0, 10) === this.filtros.criado_em.slice(0, 10);
      });
      if (filtrandoSatusId) filtrados = filtrados.filter((f) => {
        return f.status_id == this.filtros.status_id
      });
      return filtrados;
    },
  },
  methods: {
    async getDados() {
      await representantes.listar().then(resp => {
        this.dados = resp.data;
      });
    },

    abrirInformacoes(item) {
      this.dialogDetalhes.status = true
      this.dialogDetalhes.representante = item
    },
    abrirUsuario(item) {
      let id = item.id
      let usuario_id = item.usuario_id;

      this.$confirm("Você será direcionado para o formulario de Usuarios.<br> Deseja continuar?").then((res) => {
        if (res) {
          if (!usuario_id) {
            return this.navegar("usuariosCadastrarRepresentante", {id});
          }
          return this.navegar("usuariosEditar", usuario_id);
        }
      })

    },
    irParaEdicao(id) {
      return this.navegar("representantesEditar", {id});
    },

    filtrar(obj) {
      this.filtros = obj;
    },
  },

}
</script>
