<template>
  <v-dialog v-model="status" width="800px" persistent :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Representante: {{ representante.nome_razao }}</div>
          <v-btn icon dark @click="fechar()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <div class="mt-4 mb-3">
          <span class="font-weight-bold font-size-16">Dados do Cadastro</span>
        </div>
        <v-row class="px-5">
          <v-col cols="6">
            <v-text-field
                :value="representante.cpf_cnpj ?? null"
                label="CPF/CNPJ"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.nome_razao ?? null"
                label="Nome/Razão"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="'('+representante.ddd+')' +representante.telefone ?? null"
                label="Telefone"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.status_id == 1? 'Ativo' : 'Inativo'"
                label="Status"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.cep ?? null"
                label="CEP"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.cidade +'/'+ representante.uf?? null"
                label="Cidade/UF"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
                :value="representante.logradouro ?? null"
                label="Logradouro"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.logradouro_numero ?? null"
                label="Numero"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.complemento ?? null"
                label="Complemento"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.bairro ?? null"
                label="Bairro"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.criado_em | formataData"
                label="Complemento"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.atualizado_em | formataData"
                label="Bairro"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
        </v-row>

        <div class="mt-4 mb-3">
          <span class="font-weight-bold font-size-16">Dados Bancários</span>
        </div>
        <v-row class="px-5">
          <v-col cols="6">
            <AutocompleteBancos
                :id="representante.banco"
                v-on:bancoSelecionado="bancoRetornado"
                :readonly="true"
            />
          </v-col>
          <v-col cols="6">
            <AutocompleteTipoConta
                :id="representante.tipo_conta"
                v-on:tipoContaSelecionado="tipoContaRetornado"
                :readonly="true"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.agencia ?? null"
                label="Agencia"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="representante.conta ?? null"
                label="Nº Conta"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
        </v-row>

        <div class="mt-4 mb-3">
          <span class="font-weight-bold font-size-16">Valores e Comissões</span>
        </div>
        <v-row class="px-5">
          <v-col cols="6">
            <v-text-field
                :value="representante.valor_comissao_fixa ?? 0 | dinheiro"
                label="Valor comissão Fixa"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="(representante.valor_comissao_porcento ?? 0 )+ '%'"
                label="Percentual sobre os contratos de cada Imobiliaria"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
        </v-row>


      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="orange"
            @click="fechar">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


</template>

<script>
import AutocompleteBancos from "@/components/autocomplete/Bancos";
import AutocompleteTipoConta from "@/components/autocomplete/TipoContaBanco";

export default {
  name: "DialogDetalhes",
  components: {
    AutocompleteBancos,
    AutocompleteTipoConta
  },
  props: {
    status: Boolean,
    representante: Object || null,
  },
  computed: {},
  methods: {
    exibir() {
      this.aberto = true;
    },
    fechar() {
      this.$emit('fechar');
    },
    bancoRetornado(value) {
      this.dados.banco = value;
    },
    tipoContaRetornado(value) {
      this.dados.tipo_conta = value;
    },
  }
}
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}

</style>