<template>
  <v-autocomplete
      label=""
      placeholder="Selecione o status"
      :items="options_status"
      item-text="text"
      item-value="id"
      outlined
      dense
      v-model="status_selected"
      hide-details
      clearable
      prepend-inner-icon="mdi-account-off"
      v-on:change="(event) => this.$emit('statusSelecionado', status_selected)"
      :label="label ?? 'Status do Cliente'"
  />
</template>

<script>
export default {
  name: "AutocompleteStatusCliente",
  props: {
    id: Number,
    label: String,
  },
  data: () => ({
    status_selected: null,
    options_status: [

      {id: 1, text: "Ativo"},
      {id: 2, text: "Inativo"},
    ],
  }),
  async created() {
    if (this.id) {
      this.status_selected = this.id
    }
  },
  watch: {
    id: function (val) {
      this.status_selected = val;
    }
  },
}
</script>